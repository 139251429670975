import AbstractModel from '@/modules/abstract/abstract.model'
import { api } from '@/plugins'
import { parseNumber } from '@/plugins/utils'
import criteres from '../datas/criteres'

class PrimeConfig extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      'annee',
      {
        field: 'configs',
        default: {},
      },
    ])

    this.init()
  }

  init() {
    if (!this.data.configs) {
      this.data.configs = {}
    }

    if (!this.data.configs.trimestres) {
      this.data.configs.trimestres = {}

      Array(4)
        .fill()
        .forEach((_, index) => {
          const _crits = {}
          criteres.forEach((c) => {
            _crits[c.key] = {
              tranches: Array(5)
                .fill()
                .map(() => ({ valeur: '', points: '' })),
              optimale: '',
            }
          })

          this.data.configs.trimestres[`t${index + 1}`] = {
            trimestre: index + 1,
            criteres: _crits,
          }
        })
    }

    for (let i = 1; i <= 4; i++) {
      if (!this.data.configs.trimestres[`t${i}`]) {
        this.data.configs.trimestres[`t${i}`] = {}
      }

      if (!this.data.configs.trimestres[`t${i}`].trimestre) {
        this.data.configs.trimestres[`t${i}`].trimestre = i
      }

      if (!this.data.configs.trimestres[`t${i}`].criteres) {
        this.data.configs.trimestres[`t${i}`].criteres = {}
      }

      for (let critere of criteres) {
        if (!this.data.configs.trimestres[`t${i}`].criteres[critere.key]) {
          this.data.configs.trimestres[`t${i}`].criteres[critere.key] = {
            tranches: Array(5)
              .fill()
              .map(() => ({ valeur: '', points: '' })),
            optimale: '',
          }
        }
      }
    }

    if (!this.data.configs.primeProspect) {
      this.data.configs.primeProspect = {}
    }

    if (!this.data.configs.primeProspect.tranches) {
      this.data.configs.primeProspect.tranches = []
    }

    if (!this.data.configs.coefs) {
      this.data.configs.coefs = Array(5).fill({ points: '', coef: '' })
    }
  }

  getAnnee() {
    return this.getProperty('annee')
  }

  getPoints(trimestre, critere, valeur) {
    const tranches = this.getProperty(
      `configs.trimestres.t${trimestre}.criteres.${critere}.tranches`
    )
    if (tranches) {
      const [plus, middle1, middle2, middle3, moins] = tranches

      valeur = parseNumber(valeur)
      if (parseNumber(plus.valeur) < valeur) {
        return parseNumber(plus.points)
      }
      if (parseNumber(moins.valeur) > valeur) {
        return parseNumber(moins.points)
      }

      const middle =
        [middle1, middle2].find((m) => parseNumber(m.valeur) <= valeur) ||
        middle3
      return parseNumber(middle.points)
    }
  }

  getProgress(trimestre, critere, valeur) {
    const optimale = this.getProperty(
      `configs.trimestres.t${trimestre}.criteres.${critere}.optimale`
    )
    const tranches = this.getProperty(
      `configs.trimestres.t${trimestre}.criteres.${critere}.tranches`
    )
    if (tranches && optimale) {
      const points = this.getPoints(trimestre, critere, valeur)
      const oPoints = parseNumber(optimale)
      let tIndex = null
      let oIndex = null
      let prevPoints = null
      let sens = 1
      const maxIndex = tranches.length - 1
      tranches.forEach((tranche, index) => {
        const tPoints = parseNumber(tranche.points)
        if (tPoints === points) {
          tIndex = index
        }
        if (tPoints === oPoints) {
          oIndex = index
        }
        if (prevPoints !== null) {
          sens = prevPoints > tPoints ? 1 : -1
        }

        prevPoints = tPoints
      })

      if (tIndex !== null) {
        if (oIndex === null) {
          oIndex = sens > 0 ? maxIndex : 0
        }
        if (sens > 0) {
          if (oIndex >= tIndex) {
            return 100 + oIndex - tIndex
          } else {
            const indexRef = maxIndex - oIndex
            const indexValue = maxIndex - tIndex
            return Math.round((indexValue / indexRef) * 100)
          }
        } else {
          if (oIndex <= tIndex) {
            return 100 + tIndex - oIndex
          } else {
            const indexRef = oIndex
            const indexValue = tIndex
            return Math.round((indexValue / indexRef) * 100)
          }
        }
      }
    }
  }

  getCoef(points) {
    const tranches = this.getProperty(`configs.coefs`)
    if (tranches) {
      const [plus, middle1, middle2, middle3, moins] = tranches

      points = parseNumber(points)
      if (parseNumber(plus.points) < points) {
        return parseNumber(plus.coef)
      }
      if (parseNumber(moins.points) > points) {
        return parseNumber(moins.coef)
      }

      const middle =
        [middle1, middle2].find((m) => parseNumber(m.points) <= points) ||
        middle3
      return parseNumber(middle.coef)
    }
  }

  save() {
    if (this.isNew()) {
      return api.POST('production/primes/addConfig', this).then((config) => {
        M.toast({
          html: `La configuration a été enregistrée.`,
          classes: 'green',
        })

        this.resetModelRef()
        return config
      })
    } else {
      return api.PUT('production/primes/editConfig', this).then((config) => {
        M.toast({
          html: `Le configuration a été modifiée.`,
          classes: 'green',
        })

        this.resetModelRef()
        return config
      })
    }
  }
}

export default PrimeConfig
